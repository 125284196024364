<div class="container mb-5">
    <nav class="navbar navbar-expand-lg navbar-light">
        <a class="navbar-brand animated pulse" href="#"><img src="../../assets/Potential.png"
                style="width: 200px; height: 50px;"></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav" id="ul-nav">
                <li class="nav-item active">
                    <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">About Us</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">Services</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">Contact us</a>
                </li>
            </ul>
        </div>
    </nav>
</div>
<section id="intro-section" class="section-sperator">
    <div class="container">
        <div class="row mt-5">
            <div
                class="col-sm-12 col-md-6  align-items-center justify-content-end d-flex flex-column animated fadeInLeft">
                <p class="header">
                    Make Your Business More Portifable <br>
                    ... It's time to get
                </p>
                <span class="animated pulse delay-1s " id="bolder"> BOLDER</span>
                <br />
                <p class="description">
                    We are a multidisciplinary team of developers, engineers , designers and entrepreneurs who are
                    dedicated to developing innovative solutions business solutions…. With a mission of maximizing our
                    clients potential.
                </p>
                <a class="button" style="text-decoration: none;" href="#contact">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    Ask for consultaion
                </a>
            </div>
            <div class="col-sm-12 col-md-6 d-flex justify-content-end no-padding animated fadeInRight">
                <img src="../../assets/66098 [Converted].png" id="picture" class="photo-first-panel-media" alt="">
            </div>
        </div>
    </div>
</section>
<section id="aboutus" class="p-4 mt-5">
    <div class="container">
        <div class="row mt-5 mb-5">
            <div data-aos="fade-right" data-aos-delay="2" data-aos-duration="1000"
                class="col-sm-12 col-md-8 col-lg-6 d-flex justify-content-center align-items-center ">
                <img src="../../assets/387290001.png" id="logo" alt="logo">
            </div>
            <div
                class="col-sm-12 col-md-4 col-lg-6 align-items-start justify-content-center align-items-start d-flex flex-column mt-4">
                <div class="container">
                    <h5 class="header box-shadow mb-4">What We do ?</h5>
                    <p class=" text-secondary text-start">We are dedicated to developing customized solutions
                        for our clients that highlights their potential and caterers to their needs.</p>
                    <ul class="pt-1">
                        <li class="description mb-3"><img class="correct" src="../../assets/correct.svg" alt="">
                            Business development and operational solutions that are guaranteed to improve your earning
                            potential </li>
                        <li class="description mb-3"><img class="correct" src="../../assets/correct.svg" alt="">
                            Technical and digital solutions , to improve efficiency and reduce added costs
                        </li>
                        <li class="description mb-3"><img class="correct" src="../../assets/correct.svg" alt=""> Free
                            Branding and marketing , to help you create a unique authentic identity for your business
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="section-sperator" id="services">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 col-sm-12" data-aos="zoom-in" data-aos-delay="2" data-aos-duration="1000">
                <div class="d-flex justify-content-center">
                    <h2 class="header box-shadow mb-4">Our Services</h2>
                </div>
            </div>
        </div>
        <div class="row pt-5  d-flex justify-content-center align-items-center">
            <div class="cards">
                <div class="box" data-aos="zoom-in-right" data-aos-delay="2" data-aos-duration="1000">
                    <div class="icon">
                        <img src="../../assets/development.svg" alt="">
                    </div>
                    <div class="content">
                        <h3>
                            Technical Solutions
                        </h3>
                        <p>
                            Our internal internal digital solutions include a wide variety of digital records, digital
                            billing among other technical solutions to help you automate your operation <br>
                        </p>
                        <p style="margin-top: 5px;">
                            Our External digital representation ranges from web design to mobile applications helps you
                            achieve better client participation
                        </p>
                    </div>
                </div>
                <div class="box" data-aos="zoom-in" data-aos-delay="2" data-aos-duration="1000">
                    <div class="icon">
                        <img src="../../assets/Outline.svg" alt="">
                    </div>
                    <div class="content">
                        <h3>
                            Branding and Marketing
                        </h3>
                        <p>
                            Our marketing teams has years of experience in market research and penetration thus
                            ensuring your business gets the right exposure
                        </p>
                        <p style="margin-top: 5px;">
                            Branding development to help you creat a unique identity in the market
                        </p>
                        <p style="margin-top: 5px;">
                        Our Digital marketing packages are among the most effective in building and mailing your client community .
                        </p>
                    </div>
                </div>
                <div class="box" data-aos="zoom-in-left" data-aos-delay="2" data-aos-duration="1000">
                    <div class="icon">
                        <img src="../../assets/Outline.svg" alt="">
                    </div>
                    <div class="content">
                        <h3>
                            Business Development
                        </h3>
                        <p>
                            Working with your team, as well as external vendors and service providers, we will help you develop a viable strategy and tactics to enhance your ability to pursue business development objectives more efficiently and effectively.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="section-sperator" id="contact">
    <div class="container mb-5">
        <div class="row">
            <div class="col-md-12 col-sm-12" data-aos="zoom-in" data-aos-delay="2" data-aos-duration="1000">
                <div class="d-flex justify-content-center mb-5">
                    <h2 class="header box-shadow mb-4" style="font-weight: bold;">Contact with us ?</h2>
                </div>
            </div>
            <div
                class="col-sm-12 col-md-4 col-lg-6 align-items-start justify-content-center align-items-start d-flex flex-column">
                <div class="container">
                    <div class="card" id="form-card" data-aos="fade-up">
                        <div class="card-body">
                            <h3 class="card-title">Say hello!</h3>
                            <form>
                                <mat-form-field id="email-input">
                                    <mat-label>Email</mat-label>
                                    <input matInput [formControl]="emailFormControl" [errorStateMatcher]="matcher"
                                        placeholder="Ex. person@example.com">
                                    <mat-error
                                        *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                                        Please enter a valid email address
                                    </mat-error>
                                    <mat-error *ngIf="emailFormControl.hasError('required')">
                                        Email is <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field id="input-name">
                                    <mat-label>Name</mat-label>
                                    <input matInput [formControl]="nameFormControl" [errorStateMatcher]="matcher"
                                        placeholder="Ex. Johnny Deep">
                                    <mat-error *ngIf="nameFormControl.hasError('required')">
                                        The Name is <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                                <br>
                                <mat-form-field class="w-100 mt-2">
                                    <mat-label>Leave a comment</mat-label>
                                    <textarea matInput placeholder="Ex. It makes me feel..."
                                        [errorStateMatcher]="matcher" [formControl]="messageFormControl"></textarea>
                                    <mat-error *ngIf="messageFormControl.hasError('required')">
                                        Please leave a comment
                                    </mat-error>
                                </mat-form-field>
                            </form>
                            <button type="button" [disabled]="nameFormControl.hasError('required') ||
                             nameFormControl.hasError('required') || emailFormControl.hasError('required')" class="btn"
                                id="send" (click)="send()">Send <i class="fas fa-paper-plane"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-8 col-lg-6 d-flex justify-content-center align-items-center mt-4 ">
                <div data-aos="fade-down-right" id="sms1">
                    <img src="../../assets/sms1.png" class="sms animated pulse  delay-3s infinite" alt="">
                </div>
                <div data-aos="zoom-in-left" id="sms2">
                    <img src="../../assets/sms2.png" class="sms animated pulse  delay-3s infinite" alt="">
                </div>
                <div data-aos="fade-up-right" data-aos-duration="4000" id="sms3">
                    <img src="../../assets/sms4.png" class="sms" alt="">
                </div>
                <img src="../../assets/3646946221.png" id="contact-vector" alt="logo">
            </div>
        </div>
    </div>
</section>
<footer id="footer">
    <div class="container">
        <div class="row pt-2">
            <div class="col-sm-12 col-md-4 align-items-center d-flex flex-column">
                <div class="d-flex justify-content-start flex-column" data-aos="fade-in" data-aos-delay="2"
                    data-aos-duration="1000">
                    <h2 class="text-c mt-4">Find Us</h2>
                    <br />
                    <p class="info text-c">
                        <span><i class="fas fa-map-marker-alt text-c pr-2"></i></span>
                        El Nasr St., New Maadi, Cairo, Egypt
                    </p>
                    <p class="info text-c">
                        <span><i class="fas fa-phone-volume text-c pr-2"></i></span>
                        +20 121 222 2986
                    </p>
                    <p class="info text-c">
                        <span><i class="far fa-envelope text-c pr-2"></i></span>
                        potential@gmail.com
                    </p>
                </div>
            </div>
            <div class="col-sm-12 col-md-4 text-white align-items-center d-flex flex-column">
                <ul class="navbar-nav footer-links mt-3" data-aos="fade-in" data-aos-delay="2" data-aos-duration="1000">
                    <li>
                        <a class="nav-link text-c" href="#">Home</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-c " href="#">About Us</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-c " href="#">Services</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-c " href="#">Contact us</a>
                    </li>
                </ul>
            </div>
            <div class="col-sm-12 col-md-4 align-items-center d-flex flex-column">
                <div class="d-flex justify-content-start flex-column mt-4">
                    <img src="../../assets/Potential-044.png" style="width: 200px; height: 50px;" class="pr-6" alt="">

                    <div class="social-menu" data-aos="zoom-in" data-aos-delay="2" data-aos-duration="1000">
                        <ul>
                            <li><a href=""><i class="fa fa-facebook"></i></a></li>
                            <li><a href=""><i class="fa fa-twitter"></i></a></li>
                            <li><a href=""><i class="fa fa-linkedin"></i></a></li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
    </div>
</footer>
<div class="copyright">
    &copy; Copyright &copy; {{ test | date: "yyyy" }} All rights reserved | Potentialeg.com
</div>