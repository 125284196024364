import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { MainComponent } from './main/main.component';

const routs :Routes = [
    {path:'' , component:MainComponent}
]

@NgModule({
    imports: [
      CommonModule,
      BrowserModule,
      RouterModule.forRoot(routs, {
      useHash: false
    })],
    exports: [RouterModule]
  })
  export class AppRoutingModule { }