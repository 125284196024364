import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';

import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ContactService } from './contact.service';
import Swal from 'sweetalert2';


/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  constructor(private mailService: ContactService) { }
  test: Date = new Date();


  ngOnInit(): void {
    AOS.init();
  }

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  nameFormControl = new FormControl('', [
    Validators.required,
  ]);

  messageFormControl = new FormControl('', [
    Validators.required
  ])

  matcher = new MyErrorStateMatcher();
  

  send() {
    this.mailService.sendMail({
      name: this.nameFormControl.value,
      email: this.emailFormControl.value,
      comment: this.messageFormControl.value
    }).subscribe(res => {
      Swal.fire(
        'Thank You !',
        'Your message has been sent successfully!',
        'success'
      )
    })
  }

}
