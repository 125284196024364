import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  SERVER_URL = "https://bk.sortp.ai/sortp";

  constructor(private http: HttpClient) {}  

  sendMail(data):Observable<any>{
    return this.http.post(`${this.SERVER_URL}/potential/v1/contactus`,data)
  }


}
